<template>
  <!-- 合作企业管理管理 -->
  <div class="hangOutManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <!-- <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addRow"
        >
          添加
        </el-button>
      </div> -->
      <Table :operation-button-width="236" :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading">
        <template #partnerProvince="{ row }">
          <div>{{ row.partnerProvince }}/{{ row.partnerCity }}/{{ row.partnerCounty }}</div>
        </template>
      </Table>
      <!-- 分页  -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { timestampToTime } from '@/utils/util'
import { agentSupplierList, agentSupplierTerminationOrCooperation, agentSupplierDelete } from '@/api/generationMining'

export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      editFormData: {},
      itemData: [
        { label: '代采商', prop: 'cmpName', width: 180 },
        { label: '合作企业', prop: 'partnerName', width: 180 },
        { label: '企业性质', prop: 'plantType', width: 80, child: this.$store.getters.getDictionaryItem('AgentSupplierInfoNature') },
        { label: '联系人', prop: 'contacts', width: 100 },
        { label: '联系电话', prop: 'contactPhone', width: 120 },
        { label: '交收仓库', prop: 'deliveryInventoryName', width: 180 },
        { label: '企业所在地区', prop: 'partnerProvince', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'cooStatus', width: 80, child: [{ value: '0', label: '终止' }, { value: '1', label: '合作' }] }
      ],
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'partnerName' },
        { type: 'input', label: '交收仓库', value: 'deliveryInventoryName' }
      ],
      loading: false,
      total: 0,
      operationButton: [
        // { bType: 'primary', label: '编辑', handleEvent: this.addRow },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
        // { num: 1, val: 'cooStatus', bType: 'danger', label: '终止', handleEvent: this.modifyState },
        // { num: 0, val: 'cooStatus', bType: 'primary', label: '恢复', handleEvent: this.modifyState },
        // { num: 0, val: 'cooStatus', bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    viewDetails(row) {
      this.$router.push({ path: '/crmManagement/cooperativeEnterpriseDeails', query: { id: row.id }})
    },
    // 添加 or 编辑
    addRow(row) {
      if (row.constructor === Object) {
        this.$router.push({ path: '/crmManagement/editCooperativeEnterprise', query: { id: row.id }})
        return
      }
      this.$router.push({ path: '/crmManagement/addCooperativeEnterprise' })
    },
    // 更改状态editCooperativeEnterprise
    modifyState(row) {
      this.$confirm(`确定${row.cooStatus === 1 ? '终止' : '恢复'}合作企业${row.partnerName}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          agentSupplierTerminationOrCooperation({ id: row.id, cooStatus: row.cooStatus === 1 ? 0 : 1 }, () => {
            this.$message.success('成功！')
            this.getdata(true)
          })
        }).catch()
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      agentSupplierList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.createTime = timestampToTime(item.createTime)
        })
        this.total = res.data.total
      })
    },
    // 删除
    deleteRow(row) {
      this.$confirm(`确定删除合作企业${row.partnerName}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          agentSupplierDelete(row.id, () => {
            this.$message.success('成功！')
            this.getdata(true)
          })
        })
        .catch()
    }
  }
}
</script>
